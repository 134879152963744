const API_URL = process.env.REACT_APP_API_HOST
const apiStage = process.env.NODE_ENV === 'production' ? 'default' : 'default'
// const apiBase = API_URL + apiStage

export async function getDeals(postcode) {
  document.body.classList.remove('landing')
  postcode = postcode.replace(' ', '')
  try {
    const res = await fetch(`https://lamfwrxqpa.execute-api.eu-west-2.amazonaws.com/default/getPizzaDeals?postcode=${postcode}`, {
      headers: { 'Content-Type': 'application/json' },
    })
    return res.json()
  } catch (e) {
    throw new Error(e.message)
  }
}
