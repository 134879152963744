import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

const styles = {
  backgroundColor: '#f94144',
  padding: '2rem',
  display: 'flex',
  borderRadius: '5px',
  color: '#000',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '10rem',
}

export default function ErrorMessage({ message = 'An unexpected error occured. Please try again later' }) {
  return (
    <div className='error' style={styles}>
      <FontAwesomeIcon icon={faExclamationTriangle} size='3x' />
      <p>{message}</p>
    </div>
  )
}
