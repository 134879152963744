import React, { useEffect } from 'react'
import Loading from './Loading'
import Deal from './Deal'
import './DealList.css'
import { getDeals } from '../utils/api'
import ErrorMessage from './ErrorMessage'
import { toSnakeCase } from '../utils/lib'
import { Container, Button } from '@mui/material'
import { gaEvents } from '../utils/ga-events'

export default function DealList({ postcode, setPostcode }) {
  const [deals, setDeals] = React.useState([])
  const [error, setError] = React.useState(null)

  useEffect(() => {
    document.title = `Pizza Deals for ${postcode.toUpperCase()}`
    gaEvents.searchedPostcode(postcode)
    document.body.classList.remove('landing')
    getDeals(postcode)
      .then((json) => {
        if (json.errorMessage) {
          throw new Error(json.errorMessage)
        }

        const dealsByRestaurant = []
        json.forEach(deal => {
          dealsByRestaurant[deal.Restaurant] = dealsByRestaurant[deal.Restaurant] ? [...dealsByRestaurant[deal.Restaurant], deal] : [deal]
        })
        setDeals(dealsByRestaurant)
      })
      .catch((e) => {
        console.error(e)
        setError(e.message)
      })
  }, [postcode])

  return Object.keys(deals).length === 0 ? (
    error === null ? (
      <Loading text={`Getting your deals`} />
    ) : (
      <ErrorMessage />
    )
  ) : (
    <div className='deal-lists'>
      <Container maxWidth='lg' className='heading deals deal-list'>
        <h1>Showing {Object.values(deals).flat().length} deals for {postcode.toUpperCase()}</h1>
        <Button variant='contained' onClick={() => setPostcode(null)}>Change postcode</Button>
      </Container>

      <Container maxWidth='lg' className='deals'>
      <div key="Top Vouchers" className='deal-list'>
        <h2>Top Vouchers</h2>
        <small>*Vouchers are ranked by highest saving and lowest spend</small>
        {/* <small>Find out more about how we rank deals here</small> */}
        <div className='carousel'>
          {Object.values(deals).flat()
            .sort((a, b) => b.Score - a.Score)
            .filter(deal => deal.DealType === "%")
            .map((deal, index) => (
              <Deal className={`deal ${toSnakeCase(deal.Restaurant)}`} key={index} deal={deal}/>
            )).slice(0, 5)}
        </div>
      </div>
    </Container>

    <Container maxWidth='lg' className='deals'>
      <div key="Top Deals" className='deal-list'>
        <h2>Top Deals</h2>
        <small>*Deals are ranked based on the cost per inch of pizza</small>
        <div className='carousel'>
          {Object.values(deals).flat()
            .sort((a, b) => b.Score - a.Score)
            .filter(deal => deal.DealType === "")
            .map((deal, index) => (
              <Deal className={`deal ${toSnakeCase(deal.Restaurant)}`} key={index} deal={deal}/>
            )).slice(0, 5)}
        </div>
      </div>
    </Container>
    
    <Container>
      <div key="All Deals" className='deal-list'>
        <h2>All Deals</h2>
        <small>If you don't agree with our top ranking deals, you can view them all here</small>
      </div>
    </Container>
    
      {Object.entries(deals).map((dealList) => {
        return (
          <Container maxWidth='lg' className='deals' key={dealList[0]}>
            
            <div key={dealList[0]} className='deal-list'>
              <h2>{dealList[0]}</h2>
              <div className='carousel'>
                {dealList[1]
                  .sort((a, b) => b.Score - a.Score)
                  .map((deal, index) => (
                    <Deal className={`deal ${toSnakeCase(deal.Restaurant)}`} key={index} deal={deal}/>
                  ))}
              </div>
            </div>
          </Container>
        )
      })}
    </div>
  )
}
