import React, { useEffect } from 'react'
import logo from '../pizza-racoon.png'
import { Stack, Container, Button, TextField } from '@mui/material'
import './Landing.css'
// import AdBlockDetectedPopup from './AdBlockDetectedPopup'
import { useDetectAdBlock } from 'adblock-detect-react'
import CookieConsent from "react-cookie-consent";
import {useNavigate} from "react-router-dom"

export default function Landing({ setPostcode }) {
  const navigate = useNavigate();
  const [input, setInput] = React.useState('')
  const adBlockDetected = useDetectAdBlock()
  const handleSubmit = (e) => {
    e.preventDefault()
    !adBlockDetected && setPostcode(input)
  }
  useEffect(() => {
    document.title = "Pizza Dealer - Fresh pizza deals, straight out the oven"
    document.body.classList.add('landing')
  })
  return (
    <main id='pizza-logo' role='main' className='inner cover'>
      <div className='hero-bg'>&nbsp;</div>
      <Container maxWidth='sm' className='landing'>
        <img src={logo} className='logo' alt='Go Pizza logo' />
        <h1>OhMy.Pizza!</h1>
        <p>
          I'll compare all deals from your local Domino's, Pizza Hut and Papa John's restaurants, finding you the best deal in your area.
        </p>
        <p>I just need your postcode to start.</p>
        <form onSubmit={handleSubmit}>
          <Container maxWidth='xs'>
            <Stack spacing={2}>
              <TextField
                id='filled-basic'
                label='Enter Postcode'
                variant='filled'
                name='postcode'
                onChange={(e) => setInput(e.target.value)}
              />
              <Button type='submit' variant='contained'>
                Go
              </Button>
            </Stack>
          </Container>
        </form>

        <Container>
          <small id='privacy' onClick={()=>navigate("/privacy-policy")}>Privacy Policy</small>
        </Container>
      </Container>

      {/* {adBlockDetected === true && <AdBlockDetectedPopup />} */}
      <CookieConsent>This website uses cookies to enhance the user experience.</CookieConsent>
    </main>
  )
}
