import React from 'react'
import { Button, Card, CardContent } from '@mui/material'

export default function Deal({ className, deal }) {

  if (deal.StudentDeal) {
    var studentDeal = <p>Student Deal Only</p>;
  } else {
    var studentDeal = <></>
  }

  return (
    <Card sx={{ display: 'flex', width: 300, maxWidth: '60vw' }} className={`deal ${className}`} raised={true}>
      <CardContent sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <h3>{deal.DealName}</h3>
        <p>{deal.DealDesc}</p>
        {studentDeal}
        {deal.DealUrl && (
          <Button variant='contained' href={deal.DealUrl} target='_blank' rel='noreferrer'>
            Choose deal
          </Button>
        )}
      </CardContent>
    </Card>
  )
}
