import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

export default function Loading({ text = 'Loading', speed = 300 }) {
  const [content, setContent] = React.useState(text)
  const styles = {
    display: 'block',
    marginTop: '7em',
    textAlign: 'center',
  }
  React.useEffect(() => {
    var interval = window.setInterval(() => {
      content === text + '...' ? setContent(text) : setContent(content + '.')
    }, speed)

    return () => {
      clearInterval(interval)
    }
  }, [text, content, speed])
  return (
    <span className='loader' style={styles}>
      <FontAwesomeIcon icon={faSpinner} size='3x' color='#000' className='fa-spin' />
      <p>{content}</p>
    </span>
  )
}
