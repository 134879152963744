import React from 'react'
import { createTheme } from '@mui/material/styles'
import ScopedCssBaseline from '@mui/material/ScopedCssBaseline'
import { ThemeProvider } from '@mui/system'
import './App.css'
import Landing from './Landing'
import DealList from './DealList'
import {useNavigate} from "react-router-dom"

const Home = () => {
  const navigate = useNavigate();

  const [postcode, setPostcode] = React.useState(null)
  const handlePostcodeChange = (postcode) => {
    window.scrollTo(0, 0)
    setPostcode(postcode)
  }
  const theme = createTheme({
    palette: {
      primary: {
        // light: will be calculated from palette.primary.main,
        main: '#000',
        // dark: will be calculated from palette.primary.main,
        // contrastText: will be calculated to contrast with palette.primary.main
      },
      secondary: {
        // light: '#0066ff',
        main: '#c67700',
        //   // dark: will be calculated from palette.secondary.main,
        //   // contrastText: '#ffcc00',
      },
      // Provide every color token (light, main, dark, and contrastText) when using
      // custom colors for props in Material UI's components.
      // Then you will be able to use it like this: `<Button color="custom">`
      // (For TypeScript, you need to add module augmentation for the `custom` value)
      // custom: {
      //   light: '#ffa726',
      //   main: '#f57c00',
      //   dark: '#ef6c00',
      //   contrastText: 'rgba(0, 0, 0, 0.87)',
      // },
      // Used by `getContrastText()` to maximize the contrast between
      // the background and the text.
      contrastThreshold: 3,
      // Used by the functions below to shift a color's luminance by approximately
      // two indexes within its tonal palette.
      // E.g., shift from Red 500 to Red 300 or Red 700.
      tonalOffset: 0.2,
    },
  })
  return (
    <ScopedCssBaseline sx={{ backgroundColor: 'transparent' }}>
      <ThemeProvider theme={theme}>
        <div className='App'>{postcode ? <DealList postcode={postcode} setPostcode={setPostcode} /> : <Landing setPostcode={handlePostcodeChange} />}</div>
      </ThemeProvider>
    </ScopedCssBaseline>
  )
}

export default Home
