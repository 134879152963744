import React from 'react'
import ReactGA from 'react-ga4'
import Home from './components/Home'
import PrivacyPolicy from './components/PrivacyPolicy'
import {BrowserRouter,Routes,Route} from "react-router-dom";

ReactGA.initialize("G-90P6TXJFRP")

function App() {
  return (
      <>
      <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>}/>
      </Routes>
      </BrowserRouter>
      </>
  )
}

export default App
